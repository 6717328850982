// extracted by mini-css-extract-plugin
export var mainContainer = "securityAudits-module--mainContainer--QqfU-";
export var contentContainer = "securityAudits-module--contentContainer--cM9aZ";
export var csaHeader = "securityAudits-module--csaHeader--obnDL";
export var deliverableSection = "securityAudits-module--deliverableSection--iW+IX";
export var approachSection = "securityAudits-module--approachSection--l7odf";
export var pricingSection = "securityAudits-module--pricingSection--SNprN";
export var processSection = "securityAudits-module--processSection--IWrdM";
export var flexItem = "securityAudits-module--flexItem--LjqOw";
export var flexColumn = "securityAudits-module--flexColumn--CCAi1";
export var mainBackground = "securityAudits-module--mainBackground--HAijD";